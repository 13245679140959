@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Plus Jakarta Sans', 'Noto Sans Thai', 'Roboto', sans-serif;
    font-size: 14px;
}


a {
    background-color: transparent;
    color: #111;
    text-decoration: none;
}

a:hover {
    /*color: #0056b3;*/
}


.ant-modal-mask {
    z-index: 999999 !important;
}

.ant-modal-wrap {
    z-index: 9999999 !important;
}

table td,
table th {
    font-family: 'Roboto', sans-serif !important;
}

table th {
    font-size: 14px !important;
    font-weight: bold;
}


.fileInput .MuiInputAdornment-root {
    margin-top: 0 !important;
}

.fileInput label span {
    font-size: 12px !important;
}


.MuiCardHeader-title {
    font-size: 1.3rem !important;
}


.custom-dialog-visible .MuiDialog-paper {
    overflow: visible !important;
    overflow-y: visible !important;
}


.pac-container {
    z-index: 998888999;
    box-shadow: none !important;
}

.text-label {
    cursor: default;
}

.text-label .MuiInputBase-root:hover,
.text-label .MuiInputBase-root.Mui-focused,
.text-label .MuiInputBase-root {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: transparent !important;
    cursor: default !important;
}

.text-label .MuiInputBase-root svg {
    opacity: 0;
}
